import require from '@/utils/require';
import { network, auto } from '@/config/geteway';

// 运费报价分页
export const findByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/receivablebillmaster/findReceivableBillMasterByPage`,
    method: 'POST',
    data
  });
};
// 账单详情
export const lookDetail = function(data = {}) {
  return require({
    url: `${network}/${auto}/receivablebillmaster/lookDetail`,
    method: 'POST',
    data
  });
};
// 月结账单分页
export const findRByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/accountreceivable/findAccountReceivableByPage`,
    method: 'POST',
    data
  });
};
// 月账单详情
export const lookRDetail = function(data = {}) {
  return require({
    url: `${network}/${auto}/accountreceivable/lookDetail`,
    method: 'POST',
    data
  });
};
export function exportExcel(ids) {
  return require({
    data: {
      ids
    },
    url: `${network}/${auto}/receivablebillmaster/downloadBills`,
    // 设置转换二进制
    responseType: 'blob',
    method: 'post'
  });
}

