<template>
  <div class="monthlydetails teblestyle">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/commodity')">首页</span> > <span class="">财务管理</span>
      </p>
    </div>
    <div class="c-box">
      <div class="infotit">
        <div><span class="dian">●</span>对账单编号：
          <div class="tHspan"><p>{{ data.dzdNo }}</p>
          </div>
        </div>
        <div><span class="dian">●</span>对账单总金额：
          <div class="tHspan">
            <p v-for="(item,i) in data.receivableAmount" :key="i">{{ item }}</p>
          </div>
        </div>
        <div><span class="dian">●</span>已付款金额：
          <div class="tHspan">
            <p v-for="(item,i) in data.receivedAmount" :key="i">{{ item }}</p>
          </div>
        </div>
        <div><span class="dian">●</span>待付款金额：
          <div class="tHspan">
            <p v-for="(item,i) in data.uncalledAmount" :key="i">{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="tablediv">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="billCode" label="账单号" align="center" />
          <el-table-column prop="amountFormat" label="账单金额" align="center">
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.balanceAmountList" :key="i">{{ item }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="receivedAmount" label="已付款金额" align="center" />
          <el-table-column prop="status" label="账单状态" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.status==0">待核销</p>
              <p v-if="scope.row.status==1">核销完成</p>
            </template>
          </el-table-column>
        </el-table>
        <div class="heji">
          <span class="tit">合计：</span>
          <div class="Hspan">
            <p v-for="(item,i) in data.receivableAmount" :key="i">{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lookRDetail } from '@/api/finance.js';
export default {
  name: 'Monthlydetails',
  props: {

  },
  data: function() {
    return {
      data: {},
      tableData: []
    };
  },

  computed: {

  },
  watch: {

  },
  created() {
    this.init();
  },
  mounted() {

  },
  methods: {
    init() {
      const id = this.$route.query.id;
      if (id) {
        this.lookRDetail();
      } else {
        this.$router.push({ path: '/financelist' });
      }
    },
    // 请求数据
    lookRDetail() {
      const id = this.$route.query.id;
      lookRDetail({ id }).then(result => {
        console.log(result.data);
        if (result.data.code == 0) {
          const data = result.data.data;
          this.data = data;
          this.tableData = data.receivableBillMasterVOS;
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};

</script>

<style scoped>
/*标题*/
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}

.infotit{width: 100%;display: flex;}
.infotit div{width:55%;line-height:80px;font-size:16px;font-family:Microsoft YaHei;font-weight:bold;color:#333333;}
.infotit .dian {margin-right: 6px;color: #507eff;}
/*表格*/
.tablediv {width: 100%;border: 1px solid #eeeeee;border-radius:10px}
.heji {padding-right: 20px;text-align: right;min-height: 45px;padding-top: 10px}
.heji .tit {color: #333333;font-weight: bold;}
.Hspan,.tHspan{display: inline-table;}
.tHspan>p{line-height:23px;margin:0;}
.Hspan>p{color: red;}
</style>
